export const award = {
    selectedAward: null,
    awardsData: []
};


export const mutations = {
    SELECT_AWARD(state, award) {
        state.selectedAward = award;
    },
    STORE_AWARDS(state, awards) {
        state.awardsData = awards
    }
};

export const actions = {
    selectAward({ commit }, award) {
        commit("SELECT_AWARD", award);
    },
    storeAwards({ commit }, awards) {
        commit("STORE_AWARDS", awards);
    }
}