export const user = {
  selectedUser: null,
  reloadData: false,
};

export const mutations = {
  SELECT_USER(state, data) {
    state.selectedUser = data;
  },
};

export const actions = {
  selectUser({ commit }, data) {
    commit("SELECT_USER", data);
  },
  getUser({ commit, state }) {
    return state.selectedUser;
  },
};
