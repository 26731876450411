export const state = {
    selectedCertificate: null,
    certificatesData: []
};

export const mutations = {
    SELECT_CERTIFICATE(state, certificate) {
        state.selectedCertificate = certificate;
    },
    STORE_CERTIFICATES(state, certificates) {
        state.certificatesData = certificates
    }
};

export const actions = {
    selectCertificate({ commit }, certificate) {
        commit("SELECT_CERTIFICATE", certificate);
    },
    storeCertificates({ commit }, certificates) {
        commit("STORE_CERTIFICATES", certificates);
    }
}