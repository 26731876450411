export const state = {
  analytics: "Total number of users",
};

export const mutations = {
  SELECT_ANALYTICS(state, analytics) {
    state.analytics = analytics;
  },
};

export const actions = {
  selectAnalytics({ commit }, analytics) {
    commit("SELECT_ANALYTICS", analytics);
  },
};
