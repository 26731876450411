export const state = {
    selectedCountry: null,
    countriesData: []
};

export const mutations = {
    SELECT_COUNTRY(state, country) {
        state.selectedCountry = country;
    },
    STORE_COUNTRIES(state, countries) {
        state.countriesData = countries
    }
};

export const actions = {
    selectCountry({ commit }, country) {
        commit("SELECT_COUNTRY", country);
    },
    storeCountries({ commit }, countries) {
        commit("STORE_COUNTRIES", countries);
    }
}