export const state = {
  tasks: [],
  five_tasks: [],
};

export const mutations = {
  SET_TASKS(state, tasks) {
    state.tasks = tasks;
    state.five_tasks = tasks.slice(0, 5);
    console.log("ttt", state.tasks);
  },
};

export const actions = {
  setTasks({ commit }, tasks) {
    commit("SET_TASKS", tasks);
    console.log("set tasks");
  },
};
