import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import "@/assets/scss/plugins/vueformulate.scss";
import { TagsInputPlugin } from "@/vueformulate";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";



Vue.use(VueFormulate, {
  plugins: [TagsInputPlugin],
  uploader: async function (file, progress, error) {
    try {
      return;
      // const formData = new FormData();
      // formData.append("file", file);
      // const result = await fetch(
      //   "http://cdn.maxhomeproperty.com/api/upload",
      //   // "https://cdnnetwork.mvp-apps.ae/api/upload",
      //   {
      //     method: "POST",
      //     body: formData,
      //   }
      // );
      // progress(100); // (native fetch doesn’t support progress updates)
      // return await result.json();
    } catch (err) {
      error("Unable to upload file");
    }
  },
});
