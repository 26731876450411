import TagsInput from './tags-input'

export  function TagsInputPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            TagsInput,
        },
        library: {
            "tags-input": {
                classification: 'search',
                component: 'TagsInput'
            },
        },
    })
}