<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notification } from "@/config/api/notification";
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    async getTasks() {
      let api = notification.getTasks;
      var res = await this.generateAPI(api);
      this.$store.dispatch("notifications/setTasks", res.data.data);
    },
  },
  mounted() {
    // console.log("app test main");
    this.getTasks();
  },
};
</script>

<style>
.rowClass {
  cursor: pointer !important;
}

.rowClass:hover {
  background-color: #23829f38 !important;
  color: #0283ab !important;
}

.card-shadow {
  margin-bottom: 33px;
  box-shadow: 2px 1px rgba(155, 0, 0, 0.08);
  border-radius: 12px;

}

.no-bg-btn {

  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;

}

.no-bg-btn-left {

  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  padding-left: 0;

}

.pointer {
  cursor: pointer;

}
</style>