import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import "@/assets/scss/app.scss";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { initFirebaseBackend } from "./helpers/firebase/authUtils";

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

import "@/plugins/vue-formulate";
import "@/plugins/api-gen";
import "@/plugins/moment";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { ColorPicker, ColorPanel } from "one-colorpicker";
import ToggleButton from "vue-js-toggle-button";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}; 
if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(ToggleButton);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBk9P6mnMt_4UvtodD0qsJPOZAiR-RZU8U",
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(ColorPanel);
Vue.use(ColorPicker);

Vue.component("apexchart", VueApexCharts);
Vue.component("pulse-loader", PulseLoader);
Sentry.init({
  Vue,
  release: "version@" + process.env.npm_package_version,
  dsn: "https://ae1f22d1fb1d416281a54919486cada9@o1208916.ingest.sentry.io/6395863",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "mvp-apps.ae", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
