export const state = {
    loading: false,
};

export const mutations = {
    TOGGLE_ACTIVE(state,value) {
        state.loading=value
    },
};


export const actions = {
    make_loader_active({ commit }) {
        commit('TOGGLE_ACTIVE', true);
    },
    make_loader_inactive({ commit }) {
        commit('TOGGLE_ACTIVE', false);
    },
};
